.contact-body{
    padding: 120px;
    text-align: left;
}

.contact-form {
    display: inline;
}

.socials-container {
    margin-top: 60px;
    /* margin-bottom: ; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    /* height: 100%; */
    /* position: absolute; */
    /* height: calc(100% - 300px); */
}

.socials-display {
    display: flex;
    align-items: center;
    justify-content: center;
}

.instagram-container {
    width: 20vw;
    height: 50vh;
    background-color: #7F0019;
    border-radius: 5%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.instagram-container:hover .ig {
    opacity: 50%;
}

.instagram-container:hover {
    background-color: #4A000F;
}

.resume-container {
    width: 20vw;
    height: 50vh;
    background-color: #BFA58A;
    margin-left: 20px;
    border-radius: 5%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.resume-container:hover {
    background-color: #7C6B5A;
}

.resume-container:hover .resume {
    opacity: 50%;
}

.linkedin-container{
    width: 20vw;
    height: 50vh;
    background-color: #F7F8EA;
    margin-left: 20px;
    border-radius: 5%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.ig {
    width: 5vw;
    height: auto;
    transition: 0.2s;
}

.linkedin {
    width: 5vw;
    height: auto;
    transition: 0.2s;
}

.ig-handle {
    position: absolute;
    bottom: 36px;
    left: 12px;
    color: white;
}

.ig-label {
    position: absolute;
    bottom: 12px;
    left: 12px;
    font-size: 1.5em;
    font-weight: bold;
    color: white;
}

.linkedin-handle {
    position: absolute;
    bottom: 36px;
    left: 12px;
    color: black;
}

.linkedin-label {
    position: absolute;
    bottom: 12px;
    left: 12px;
    font-size: 1.5em;
    font-weight: bold;
    color: black;
}

.resume {
    width: 5vw;
    height: auto;
    transition: 0.2s;
    border-radius: 10px;
}

.linkedin-container:hover {
    background-color: #C9CABF;
}

.linkedin-container:hover .linkedin {
    opacity: 50%;
}

.message-text-light {
    width: 80vw;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background-color: rgb(235, 235, 235);
    transition: 0.2s;
    border-radius: 10px;
    padding: 12px;
    resize: none;
}

.message-text-light:hover {
    background-color: rgb(202, 202, 202);
}

.message-text-dark:hover {
    background-color: #3a3a3a;
}

.message-text-dark {
    width: 80vw;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background-color: #1E1E1E;
    transition: 0.2s;
    color: white;
    border-radius: 10px;
    padding: 12px;
    resize: none;
}

.email-text-light {
    width: 80vw;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background-color: rgb(235, 235, 235);
    transition: 0.2s;
    border-radius: 10px;
    padding-left: 12px;
}

.email-text-dark {
    width: 80vw;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background-color: #1E1E1E;
    transition: 0.2s;
    color: white;
    border-radius: 10px;
    padding-left: 12px;
}
.email-text-light:hover {
    background-color: rgb(202, 202, 202);
}

.email-text-dark:hover {
    background-color: #3a3a3a;
}

.name-text-light {
    width: 80vw;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background-color: rgb(235, 235, 235);
    transition: 0.2s;
    border-radius: 10px;
    padding-left: 12px;
}

.name-text-dark {
    width: 80vw;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background-color: #1E1E1E;
    transition: 0.2s;
    color: white;
    border-radius: 10px;
    padding-left: 12px;
}

.name-text-light:hover {
    background-color: rgb(202, 202, 202);
}

.name-text-dark:hover {
    background-color: #3a3a3a;
}
.submit-btn {
    margin-top: 12px;
    /* width: 15vw; */
    min-width: 24px;
    transition: 0.2s;
    border: none;
    height: 40px;
    border-radius: 10px;
    background-color: #eb4034;
    color: #ffffff;
    font-weight: bold;
}


.message-prompt {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
}

/* #box {
    margin-top: 20px;
    margin-bottom: 20px;
} */

.input-prompt {
    font-weight: bold;
}

@media (max-width: 760px) {
    .message-text-light {
        width: 70vw;
        height: 120px;
    
    }
    
    .email-text-light {
        width: 70vw;
        height: 40px;
    
    }
    
    .name-text-light {
        width: 70vw;
        height: 40px;
    
    }
    .contact-body {
        padding: 60px;
    }

    
    .socials-display {
        display: block;
        margin-left: 0;
    }

    .instagram-container {
        margin-top: 20px;
        width: 80vw;
        height: 20vh;
        border-radius: 20px;
    }
    .resume-container {
        width: 80vw;
        height: 20vh;
        margin-left: 0;
        margin-top: 20px;
        border-radius: 20px;
    }
    .linkedin-container {
        width: 80vw;
        height: 20vh;
        margin-left: 0;
        margin-top: 20px;
        border-radius: 20px;
    }

    .socials-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .ig {
        width: 60px;
    }

    .resume {
        width: 60px;
    }

    .linkedin {
        width: 60px;
    }
}