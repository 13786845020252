.aboutme-body {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* padding-top: 150px;
    padding-left: 120px;
    padding-bottom: 120px;
    padding-right: 120px; */
}

.aboutme-wrapper {
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    padding-top: 120px;
    padding-left: 120px;
    padding-bottom: 120px;
    padding-right: 120px;
}


.aboutme-container {
    width: 100%;
}

.media-container-about {
    display: block;
    margin: 24px
}

.image-media {
    position: relative;
    display: block;
}

.image-about {
    /* height: 400px; */
    /* display: grid;
    grid-template-columns: 20vw 20vw; */
    width: 20vw;
    height: auto;
    /* grid-row: auto auto; */
    /* margin-left: 12px; */
}

.image-grid {
    display: grid;
    grid-template-columns: 20vw 20vw;
}

.chinese-caption {
    position: absolute;
    top: 12px;
    left: 12px;
    writing-mode: vertical-lr;
    text-orientation: upright;
    letter-spacing: 12px;
    font-size: 2em;
    font-weight: bold;
    color: #7F0019;
    text-align: left;
}

.media-body {
    display: flex;
    margin-top: 30px
}

.caption {
    position: absolute;
    bottom: 12px;
    left: 12px;
    color: #7F0019;
    font-size: 24px;
}

.title {
    position: absolute;
    bottom: 40px;
    left: 12px;
    font-size: 36px;
    font-weight: bold;
    color: #7F0019;
}

.about-item-index {
    font-size: 2em;
    font-weight: bold;
    color: #7F0019;
    margin-left: 24px;
    display: flex;
}

.about-text-container {
    display: block;
    justify-content: start;
    align-items: flex-start;
}

.about-item-title {
    font-size: 2.3em;
    font-weight: bold;
    margin-left: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    text-align: left;
}

.about-text {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    text-align: left;
    margin-top: 16px;
    margin-left: 24px;
}

.about-link {
    display: flex;
    margin-left: 24px;
    text-align: left;
}

.home-intro-en {
    user-select: none;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    font-size: 260%;
    font-weight: 700;
    text-align: left;
    line-height: 1.2;
}

.home-intro-cn {
    margin-top: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    font-size: 150%;
    text-align: left;
    
}
.home-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    /* font-size: 3em; */
}


.me {
    width: 20vw;
    height: 20vw;
    border-radius: 20px;
}

.aboutme-p1 {
    padding-left: 40px;
    text-align: left;
    font-size: 1.5em;
}

.writing-header {
    display: flex;
    margin-left: 120px;
}

.writing-title-light {
    font-size: 2em;
    font-weight: bold;
    border-top: 2px solid #eb4034;;
}

.writing-title-dark {
    font-size: 2em;
    font-weight: bold;
    border-top: 2px solid #eb4034;;
}

.writing-label {
    margin-top: 20px;
    margin-left: 50px;
}

.new-indicator {
    background-color: #c36ade;
    border-radius: 6px;
    width: 60px;
    margin-right: 50px;

}


.blog-details {
    display: flex;
    margin-top: 20px;
}

.blog-title {
    text-align: left;
    font-size: 1.7em;
}

.blogpost {
    padding-left: 120px;
    transition: 0.2s;
    border-radius: 30px;
    width: calc(100vw - 240px);
}

.blogpost:hover {
    cursor: pointer;
}

.bloglist-body {
    margin-top: 50px;
}

.blogpost-separator {
    width: calc(100vw - 240px);
    /* color: #eb4034; */
}

.blogpost-wrapper {
    display: flex;
}

.blog-body {
    padding-left: 120px;
    padding-right: 120px;
    text-align: left;
}

@media (max-width: 760px) {
    .aboutme {
        margin-top: 50px;
    }
    .new-indicator {
        margin-right: 15px;
    }
    .blogpost-separator {
        width: calc(100vw - 120px);
    }
    .blog-body {
        padding-left: 60px;
        padding-right: 60px;
    }
    .blogpost {
        padding-left: 60px;
        width: calc(100vw );
    }
    .aboutme-p1 {
        font-size: 20px;
        margin: 0;
        padding: 0;
    }
    .aboutme-wrapper {
        display: block;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 0;
        padding-bottom: 60px;
    }
    .aboutme-body {
        display: block;
        /* padding-left: 60px;
        padding-right: 60px;
        padding-top: 0; */
    }

    .me {
        /* margin-bottom: 60px; */
        width: 40vw;
        height: 40vw;
    }

    .writing-header {
        margin-left: 60px;
    }


    .aboutme-p {
        margin-top: 20px;
    }
    .home-intro-cn {
        font-size: 120%;
    }
    .home-intro-en{
        font-size: 150%;
    }

    .home-text {
        margin-right: 0;
    }

    .media-body {
        display: block;
    }

    .aboutme-container{
        display: flex;
        justify-content: center;
        align-items: center;
        /* padding-top: 50px; */
    }

    .image-about {
        width: 150px;
    }

    .image-grid {
        display: grid;
        grid-template-columns: 150px 150px;
    }
    .title {
        font-size: 24px;
    }

    .caption {
        font-size: 16px;
    }

    .chinese-caption {
        letter-spacing: 6px;
        font-size: 20px;
    }

    .about-item-index {
        margin-left: 0;
    }

    .about-item-title {
        margin-left: 0;
    }

    .about-text {
        margin-left: 0;
    }

    .about-link {
        margin-left: 0;
    }
}

