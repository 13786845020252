.html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

.home-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

.home-text{
    display: inline-block;
    justify-content: start;
    vertical-align: top;
    /* height: 75vh; */
}

.media-container {
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.2s;
}

.media-container:hover {
    scale: 1.1;
}

/* .image-container {
} */
.index-label {
    /* display: block; */
    font-size: 40px;
    font-weight: bold;
    border-bottom: 5px solid white;
    padding-bottom: 3px;
    /* text-decoration: underline white 5px; */
    color: white;
    margin-right: 12px;
    margin-top: 12px;
    top: 0;
    right: 0;
    position: absolute;
}

.character-label {
    top: 80px;
    right: 0;
    position: absolute;
    margin-right: 16px;
    font-weight:bolder;
    font-size: 20px;
    color: white;
}

.dot-label {
    top: 100px;
    right: 0;
    position: absolute;
    margin-right: 16px;
    /* font-weight:900; */
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.image {
    height: 500px;
    width: auto;
}

.icon-container {
    font-weight: bold;
    color: #CDB297;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 16px;
    margin-left: 16px;
    background-color: #7F0019;
    padding: 6px;
    border-radius: 8px;
}

.separator {
    position: absolute;
    border: 1px solid #F7F8EA;
    width: 250px;
    left: 0;
    bottom: 70px;
}

.line {
    border: 0.5px solid #7F0019;
    justify-content: right;
    width: 40vw;
}

.line-container {
    display: flex;
    align-items: flex-end;
}

.text-label {
    position: absolute;
    font-size: 12px;
    left: 0;
    margin-left: 16px;
    width: 250px;
    text-align: left;
    color: #F7F8EA;
    bottom: 90px;
    /* font-weight: bold; */
}

.disclaimer {
    text-align: left;
    font-size: 10px;
}

.page-index {
    font-size: 46px;
    margin-left: 45px;
    font-weight: bold;
    color: #7F0019;
    text-align: left;
}

.welcome{
    text-align: left;
    font-size: 54px;
    margin-left: 45px;
    font-weight: bold;
    color: black;
    margin-top: 12px;
}

.media {
    display: flex;
}

.paragraph-item {
    margin-left: 45px;
    font-size: 14px;
    text-align: left;
    margin-top: 18px;
    width: 35vw;
}

@media (max-width: 760px) {
    .media-container {
        display: block;
    }

    .page-index {
        margin-left: 0;
        font-size: 24px;
    }
    
    .welcome {
        font-size: 30px;
        margin-top: 8px;
        margin-left: 0;
        /* text-align: left; */
    }

    .home-body {
        
        margin-left: 50px;
        margin-right: 50px;
        /* margin-top: 50px; */
        display: inline-block;
        justify-content: center;
    }

    .home-text {
        /* margin-left: 50px; */
        display: block;
    }

    .paragraph-item {
        margin-left: 0;
        /* margin-left: 50px; */
        /* margin-right: 50px; */
        width: 100%;
    }

    .line {
        width: 100%;
    }

    .image {
        height: 50vh;
    }

    .media-parent {
        display: flex;
        /* align-items: left; */
        justify-content: center;
    }

    .text-label {
        width: 75%;
    }

    .separator {
        width: 80%;
    }
}

.home-introduction {
    /* height: 800px; */
    margin: 30px;
    padding-top: 1px;
    border-radius: 30px;
    box-sizing: content-box;
    background-color: #ffffff;
    box-shadow: 0 20px 25px rgba(0,0,0,0.2);

}

.home-description-intro {
    padding: 50px;
    padding-top: 10px;
    text-align: left;

}

/* body{
    background-color: #0e6ffc;
    height: 100vh;
    display: grid;
    place-items: center;
} */
.wrapper{
    height: 60px;
    margin-top: 20px;
    /* padding: 50px 30px; */
    display: flex;
    /* align-items: center; */
    border-radius: 8px;
}
.words{
    overflow: hidden;
}
.individ-word {
    display: block;
    height: 100%;
    font-size: 30px;
    text-align: left;
    padding-left: 10px;
    color: #eb4034;
    font-weight: bold;
    animation: spin_words 15s infinite;
    pointer-events: none;
}
@keyframes spin_words{
    10%{
        transform: translateY(-108%);
    }
    25%{
        transform: translateY(-100%);
    }
    35%{
        transform: translateY(-208%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-308%);
    }
    75%{
        transform: translateY(-300%);
    }
    85%{
        transform: translateY(-408%);
    }
    100%{
        transform: translateY(-400%);
    }
}

.home-name-english-wrapper {
    margin-top: 60px;
    font-size: 60px;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
}

.home-name-chinese-wrapper {
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-chinese-name {
    transition: 0.2s;
    background-color: black;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* margin-left: 20px; */
}

.home-chinese-name:hover {
    background: linear-gradient(90.07deg, #7F0019, #CDB297);
    /* background: -webkit-linear-gradient(#eee, #333); */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-english-name {
    transition: 0.2s;
    background-color: black;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 20px;
}

.home-english-name:hover {
    background: linear-gradient(90.07deg, #7F0019, #CDB297);
    /* background: -webkit-linear-gradient(#eee, #333); */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bold-description{
    background: linear-gradient(90.07deg, #7F0019, #CDB297);
    /* background: -webkit-linear-gradient(#eee, #333); */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-words-intro {
    font-size: 30px;
}

.home-wrapper {
    width: auto;
    height: auto; 
    box-sizing: border-box;
    display: flex;
    padding: 120px;
    padding-top: 150px;
}

.img-main {
    width: 35vw;
    height: auto;
    border-radius: 20px;
    z-index: 1000;
}

.home-phone {
    position: absolute;
    top: 0;
    right: 0;
    /* transform: translateY(230px) translateX(100px); */
}

.home-img-main {
    position: relative;
    
}

.home-media {
    position: relative;
}

.img-phone {
    width: 20vw;
    height: auto;
    z-index: 100;
}

.home-badtz {
    position: absolute;
    right: 0;
    z-index: 0;
    /* transform: translateY(-400px) translateX(30px); */
}

.img-badtz {
    border-radius: 50%;
    width: 15vw;
    outline: 1px solid black;
}


.home-music {
    width: 100px;
    height: 100px;
    position: absolute;
    background-color: rebeccapurple;
}

.home-text-p1 {
    font-weight: 500;
    text-align: left;
}

.home-text-sub {
    margin-top: 20px;
    font-weight: 700;
    text-align: left;
}

#unique-link {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
    text-decoration: underline;
  }
  
#unique-link:hover {
color: #eb4034;
}


@media (max-width: 760px) {
    .home-wrapper {
        display: block;
        padding-top: 60px;
        padding-left: 60px;
        padding-right: 60px;
    }

    .home-name-english-wrapper {
        display: block;
        margin-top: 20%;
    }
    .home-name-english-wrapper {
        font-size: 40px;
    }

    .home-name-chinese-wrapper {
        font-size: 30px;
    }
    .home-img-main {
        margin-top: 60px;
    }

    .img-main {
        width: 60vw;
        height: auto;
    }

}