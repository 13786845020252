.trails-body {
    padding: 120px;
    text-align: left;
}

.trails-title {
    /* margin-top: 50px; */
    
    font-size: 36px;
    font-weight: bold;
}

.trails-prev-imgs {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.img-indiv {
    height: 400px;
    margin-right: 20px;
    width: auto;
    border-radius: 10px;
}

.img-indiv-l {
    height: 200px;
    margin-right: 20px;
    width: auto;
    border-radius: 10px;
}

.trails-tagline {
    margin-top: 20px;
    font-style: italic;
    color: grey;
}

.trails-p {
    margin-top: 20px;
}

.trails-b {
    margin-top: 20px;
    font-weight: bold;
}

.trails-i {
    margin-top: 20px;
    font-style: italic;
}

.trails-tech-individ {
    margin-top: 20px;
    margin-right: 20px;
    height: 30px;
    width: auto;
}

.trails-caption {
    text-align: center;
    color: grey;
    margin-top: 20px;
}

.trails-pc {
    margin-top: 20px;
    margin-bottom: 20px;
}

.img-indiv-f {
    width: 50vw;
    height: auto;
    border-radius: 10px;
}


.img-vid {
    height: 400px;
    width: 400px;
    border-radius: 20px;
}
.img-indiv-favicon {
    width: 200px;
    height: auto;
}

@media (max-width: 760px) {
    .img-vid {
        width: 200px;
        height: 200px;
    }
    .trails-prev-imgs {
        display: block;
    }

    .img-indiv {
        margin-top: 20px;
    }
    .img-indiv-l {
        margin-top: 20px;
    }
    .trails-body {
        padding: 60px;
    }
    .trails-title {
        margin-top: 30px;
    }
}