.projectcontainer-wrapper {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.project-image-container{
    position: relative;
}

.image-hover {
    background-color: black;
    position: absolute;
    width: inherit;
    height: 200px;
}
.projectimage-container {
    display: grid;
  }
  
.project-image, .overlay {
    grid-area: 1 / 1;
    transition: 0.2s;
    border-radius: 15px;
    /* background-color: black; */
    /* opacity: 0.5; */
}

.project-source-image {
    border-radius: 15px;
    border: 1px solid black;
}


.overlay {
    opacity: 0;
    background-color: black;
    color: white;
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}


.overlay:hover {
    /* @media (max-width: 760px) {
        opacity: 0;
    } */
    opacity: 0.75;
}

#a:hover + #b {
    opacity: 0;
}

.overlay:hover .project-description {
    opacity: 1;
}

.project-line {
    border: 0.5px solid #7F0019;
    width: 50%;
    position: absolute;
    bottom: 100px;
    left: 0;
}

.project-detail-container {
    display: block;
    justify-content: start;

}

.project-description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.project-detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 24px;
    
}

.project-index {
    font-size: 36px;
    font-weight: bold;
    color: #7F0019;
}

.project-title {
    font-size: 40px;
    font-weight: bold;
}

.project-date {
    font-size: 20px;

}

.project-title-display {
    position: absolute;
    bottom: 130px;
    left: 90px;
    font-size: 36px;
    font-weight: bold;
    color: #ba0025;
    transition: 0.2s;
}

.project-line-container {
    /* display: flex; */
    align-items: flex-start;
    justify-content: start;
}

.project-tech {
    display: flex;
    align-items: flex-start;
    justify-content: start;
}

.project-buttons {
    display: flex;
    margin-left: 24px;
}


.project-button-container {
    display: flex;
    /* background-color: #7F0019; */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.individ-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    background-color: #7F0019;
    width: 120px;
    height: 50px;
    text-decoration: none;
    border-radius: 12px;
    transition: 0.2s;
}

.project-button-title {
    color: #CDB297;
    font-weight: bold;
}

.project-button-text {
    color: #CDB297;
}

.individ-button:hover {
    background-color: #4d000f;
    scale: 1.05;
    /* color: white; */
}

.individ-button:hover .project-button-title {
    color: white;
}

.individ-tech {
    margin-top: 30px;
    margin-left: 24px;
}

.mobile-description {
    display: none;
}

.project-body {
    padding: 120px;
}

.feature-title-1 {
    margin-top: 20px;
    text-align: left;
    font-weight: 800;
    font-size: 48px;
    margin-bottom: 10px;
}

.feature-title {
    margin-top: 90px;
    text-align: left;
    font-weight: bold;
    font-size: 48px;
}

.featured-projects {
    justify-content: left;
    display: flex;
    margin-top: 50px;
}

.feature-image {
    object-fit: cover;
    width: 25vw;
    /* height: 100%; */
    border-radius: 10px;
    border: 0.5px solid black;
}

.feature-container {
    /* display: flex;
    justify-content: center; */
    /* margin-left: 20px; */
    margin-right: 20px;
}

.feature-item {
    text-align: left;
    font-size: 24px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.feature-icon {
    color: #eb4034;
    transition: 0.2s;
    cursor:pointer;
}

.feature-icon:hover {
    color: #7F0019;
    transition: 0.2s;
    cursor:pointer;
}

.other-separator {
    width: calc(100vw - 240px);
    stroke: 1px grey;
}

.link-feature:hover {
    color: #ba0025;
}

.other-name {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
}

a {
    color: #ba0025;
}

.other-proj-img {
    width: 50%;
    height: auto;
    border-radius: 10px;
}

.other-img {
    display: flex;
    justify-content: center;
}

.other-proj-wrapper {
    text-align: left;
}

.other-proj-t {
    margin-top: 20px;
}

.other-proj-tlist {
    display: flex;
    margin-top: 20px;
}

.other-tech {
    margin-right: 10px;
    /* width: 30px; */
    height: 30px;    /* height: auto; */
}
.project-title-card {
    font-weight: 600;
}


@media (max-width: 760px) {
    .other-proj-img {
        width: 80%;
        height: auto;
    }
    .feature-item {
        margin-bottom: 20px;
    }
    .feature-container {
        margin-left: 0;
        margin-right: 0;
    }
    .feature-image {
        width: calc(100vw - 120px);
        
    }
    .other-separator {
        width: calc(100vw - 120px);
    }
    .featured-projects {
        display: block;
    }
    .feature-title {
        font-size: 36px;
        margin-top: 0;
    }
    .project-body {
        padding: 60px;
    }
    .project-source-image {
        width: 80%;
        height: auto;
    }

    .overlay:hover {
        opacity: 0;
    }
    #a:hover + #b {
        opacity: 1;
    }

    .project-image {
        margin-top: 30px;
    }

    .project-detail {
        margin-top: 12px;
        margin-left: 10%;
    }

    .project-buttons {
        margin-left: 10%;
    }

    .project-tech {
        margin-left: 10%;
    }

    .individ-tech {
        margin-left: 0;
        margin-right: 20px;
    }

    .project-line {
        display: none;
    }

    .project-title-display {
        display: none;
    }

    .mobile-description {
        display: flex;
        text-align: left;
    }

    .project-details-wrapper {
        padding-bottom: 5%;
    }
}