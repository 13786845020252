.topbar-container-light {
    display: flex;
    justify-content: right;
    background-color: #F6F6F6;
    position: fixed;
    width: 90vw;
    height: 10vh;
    opacity: 1;
    transition: 0.2s;
    border-radius: 16px;
    left: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translate(-50%, 0);
    z-index: 100;
    margin-top: 20px;
}
.topbar-container-dark {
    margin-top: 20px;
    display: flex;
    justify-content: right;
    background-color: #222222;
    position: fixed;
    width: 90vw;
    height: 10vh;
    opacity: 1;
    transition: 0.2s;
    border-radius: 16px;
    left: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
    transform: translate(-50%, 0);
    z-index: 100;
}

.topbar-container:hover {
    opacity: 1;
}

.topbar {
    display: flex;
    /* position: fixed; */
}

.logo-container{
    background-color: #CDB297;
    border-radius: 6px;
    padding: 6px;
    /* margin-top: 30px; */
    margin-left: 30px;
    font-weight: bold;
    color: #eb4034;
    transition: 0.2s;
}

.logo-container:hover {
    scale: 1.1;
    background-color: #8e7b67;
    color: white;
    box-shadow: 10px 5px 5px rgb(139, 139, 139);
}

.chinese-name {
    font-size: 24px;
}

.topbar-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    border-bottom: none;
}

.toggle-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    border-bottom: none;
}

.topbar-item-container:hover  {
    border-bottom: 1px solid #eb4034;
    /* color: #eb4034; */
}
.topbar-item-container:hover .topbar-item-dark {
    color: #eb4034;
}

.topbar-item-container:hover .topbar-item-light {
    color: #eb4034;
}

.topbar-item-light {
    text-decoration: none;
    font-weight: bold;
    color: black;
    transition: 0.2s;
}
.topbar-item-dark {
    text-decoration: none;
    font-weight: bold;
    color: white;
    transition: 0.2s;
}

/* .topbar-item:hover {
    color: #eb4034;
} */

.page-header-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page-information {
    width: 50%;
    padding-top: 30px;
    
}

.page-description {
    width: 50%;
    padding: 30px;
    text-align: left;
}

.page-index-display {
    font-size: 2.5em;
    font-weight: bold;
    color: #eb4034;
    display: flex;
    align-items: flex-start;
    /* padding-left: 60px; */
    margin-left: 60px;
}

.page-name {
    font-size: 3em;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    /* padding-left: 60px; */
    margin-left: 60px;
}

.page-header-parent {
    width: 100%;
    display: block;
    padding-top: 120px;
}

.aboutme-line {
    width: 100%;
    padding: 0;
    border: 0.5px solid #eb4034;
}

.aboutme-texts {
    margin-top: 12px;
    font-size: 20px;
}

.custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #eb4034;
}

.custom-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #c7382e;
  }
.topbar-spacer {
    width: 5vw;
}

@media (max-width: 760px) {
    .page-header-parent {
        padding-top: 30px;
    }
    /* .page-header-parent {
        margin-left: 50px;
        display: flex;
        justify-content: right;
        align-items: center;
        width: 100%;
    } */
    .page-header-container {
        display: block;
    }

    .page-header-wrapper {
        /* width: 100%; */
        display: flex;
        justify-content: right;
        align-items: center;
        padding-left: 50px;
    }
    .page-information {
        margin-top: 60px;
        width: 100%;
        padding: 0;
        text-align: left;
    }
    .page-description {
        width: 250px;
        /* margin-left: 60px; */
        padding: 0;
        text-align: left;
        margin-left: 60px;
        /* margin-right: 30px; */
    }

    .page-index-display {
        font-size: 30px;
    }

    .page-name {
        font-size: 42px;
    }

    .aboutme-line {
        width: 100%;
        
    }



}